import Spinner from 'components/shared/Spinner';
import FullScreenLayout from 'layouts/FullScreenLayout';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Defaults } from '../Defaults';

export default (props: { color?: 'white' | 'gray' }) => {
    const { color = 'gray' } = props;
    const bgColor = color === 'gray' ? '#e3e3e3' : 'white';

    useEffect(() => {
        document.body.style.backgroundColor = bgColor;
        return () => {
            document.body.style.backgroundColor = 'white';
        };
    });

    return (
        <Container>
            <Spinner center size="huge" color="primary" />
        </Container>
    );
};

const Container = styled(FullScreenLayout)`
    justify-content: center;
    overflow: hidden;
    align-content: center;
    justify-items: center;
    overscroll-behavior-y: none;
    div { margin-top: -20px }
    ${Defaults}
`;
