export const shared = {
    screens: {
        large: 1512,
        medium: 1200,
        small: 800,
        mobile: 480
    },
    maxWidth: 1050,
    navSize: {
        small: 60,
        other: 60,
        labelCollapse: 348
    }
};
