import { TextSize } from 'types/User';

export default (size: TextSize | undefined | null) => {
    switch (size) {
        case TextSize.XXLarge:
            return 1.5;
        case TextSize.XLarge:
            return 1.33;
        case TextSize.Large:
            return 1.15;
        case TextSize.Small:
            return 0.85;
        default:
            return 1;
    }
};
