import { isMentor } from 'util/isMentor';
import useUserContext from './useUserContext';

export const useUser = () => {
    const { data } = useUserContext();
    return data?.user;
};

export const useIsMentor = () => {
    const user = useUser();
    if (user === undefined) return undefined;
    return isMentor(user);
};
