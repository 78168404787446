import { FetchContext, FetchContextProvider } from '@bbcs/bbcs-fetch';
import { FC, PropsWithChildren, ReactNode, useContext, useEffect, useState } from 'react';
import User from 'types/User';
import UserData from 'types/UserData';
import UserDataContext from 'types/UserDataContext';

import { connect, disconnect, useChat } from '@bbcs/bbcs-chat';
import UISettings from 'types/UISettings';
import { saveUsingDyslexiaFont } from 'util/dyslexia';
import { isEmpty } from 'util/validator/utils';
import { v4 as uuid } from 'uuid';
import { clearLocalSettings } from 'hooks/usePreferences';
import ConfigContext from './ConfigContext';
import UserContext from './UserContext';

const UserContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const { config, setRegion } = useContext(ConfigContext);
    const [data, setData] = useState<UserData>(getData());
    const connector = useChat();
    const fetchContext = { config, user: { token: data?.token } };

    const logout = () => {
        removeStorage();
        disconnect();
        setData(getData());
    };

    const login = (user: User, token: string, expires: number) => {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('expires', expires.toString());
        localStorage.setItem('token', token);
        saveUsingDyslexiaFont(user.useDyslexiaFont);
        if (!isEmpty(user?.primaryRegion)) setRegion(user.primaryRegion!);
        setData(getData());
    };

    const updateUISettings = (settings: UISettings) => {
        const user = getUser();
        if (!user) return;
        localStorage.setItem('user', JSON.stringify({ ...user, ...settings }));
        // Todo: deal with the user preferences..
        saveUsingDyslexiaFont(settings.useDyslexiaFont);
        setData(getData());
        clearLocalSettings();
    };

    const context = { data, logout, login, updateUISettings };
    const expired = isExpired(data);

    useEffect(() => {
        if (!connector && data.hasUser && fetchContext.user.token) connect(fetchContext, data.user!);
    }, [data, fetchContext]);

    if (expired) logout();
    if (expired) return null;

    return <Provider fetchContext={fetchContext} userContext={context}>{children}</Provider>;
};

const Provider = (props: { userContext: UserDataContext, children: ReactNode, fetchContext: FetchContext }) => {
    const { userContext, children, fetchContext } = props;

    return (
        <UserContext.Provider value={userContext}>
            <FetchContextProvider context={fetchContext}>
                {children}
            </FetchContextProvider>
        </UserContext.Provider>
    );
};

Storage.prototype.getInt = (key: string) => {
    const value = localStorage.getItem(key);
    return value !== null ? parseInt(value, 10) : undefined;
};

const removeStorage = () => {
    clearLocalSettings();
    localStorage.removeItem('user');
    localStorage.removeItem('expires');
    localStorage.removeItem('token');
};

const getData = () => {
    const storage = {
        user: getUser(),
        expires: localStorage.getInt('expires'),
        token: localStorage.getItem('token') ?? undefined,
        deviceId: getDeviceId()
    };
    return { ...storage, hasUser: !!storage.user };
};

const getUser = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) as User : undefined;
};

const getDeviceId = () => {
    const stored = localStorage.getItem('id');
    if (stored) return stored;
    const deviceId = uuid();
    localStorage.setItem('id', deviceId);
    return deviceId as string;
};

const isExpired = (data: UserData | undefined) => {
    if (!data?.expires) return false;
    const now = Math.floor(new Date().getTime() / 1000);
    return data.expires < now;
};

export const getToken = () => {
    try {
        const data = getData();
        const expired = isExpired(data);
        if (expired || isEmpty(data?.token)) return undefined;
        return data.token;
    } catch {
        return undefined;
    }
};

export default UserContextProvider;
