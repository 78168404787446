import { useEffect, useState } from 'react';
import Config from 'types/Config';
import fetchConfig from 'util/fetchConfig';
import { isEmpty } from 'util/validator/utils';

const getRedirectUrl = (config: Config) => {
    const path = window.location.href.substring(window.origin.length);
    if (!config.region) return config.destination + path;
    const rel = encodeURI(path);
    return `${config.destination}/set-region?rel=${rel}`;
};

export const useFetchConfig = () => {
    const [region, setRegion] = useState<string | undefined>(getRegionId());
    const [config, setConfig] = useState<Config | undefined>();

    useEffect(() => {
        fetchConfig(region).then(response => {
            // If the config indicate that this site is purely a direct front-end
            // then redirect the site. Also take into account that a redirect will force
            // the region of the destination
            if (response.type === 'Redirect') {
                const destination = getRedirectUrl(response);
                setTimeout(() => {
                    window.location.assign(destination);
                }, 25);
            } else {
                setConfig(response);
            }
        });
    }, [region]);

    if (!config) return null;
    return {
        config,
        setConfig: (value?: Config) => {
            setConfig(value);
        },
        setRegion: (value?: string) => {
            setRegionId(value);
            setRegion(value);
        },
    };
};

export const setRegionId = (regionId?: string) => {
    clearRegionId();
    if (isEmpty(regionId)) return;
    localStorage.setItem('region-id', regionId!);
};

const getRegionId = () => localStorage.getItem('region-id') ?? undefined;

const clearRegionId = () => localStorage.removeItem('region-id');
