import { type DefaultTheme } from 'styled-components';
import { shared } from './shared';

export const light: DefaultTheme = {
    ...shared,
    colors: {
        default: '#3d3d3d',
        defaultInverse: '#ffffff',
        defaultSupport: '#ededed',

        primary: '#0c497a',
        primaryInverse: '#ffffff',

        secondary: '#3fab49',
        secondaryInverse: '#ffffff',

        tertiary: '#a8a8a8',
        tertiaryInverse: '#ffffff',

        error: '#cc0000',
        errorInverse: '#ffffff',

        background: '#ffffff',
        disabled: '#f4f4f4',
        lightGray: '#ededed',
        badge: '#cc0000',

        inputTextColor: '#3d3d3d',
        inputBackgroundColor: '#ffffff',
        inputBorderColor: '#a8a8a8',
    },
    type: 'light'
};
