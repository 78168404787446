const visualHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--visual-height', `${window.visualViewport!.height}px`);
    doc.style.setProperty('--visual-offset-top', `${window.visualViewport!.offsetTop}px`);
};

const layoutHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--layout-height', `${window.innerHeight}px`);
    // Fallback mechanism in case this is an older browser
    if (window.visualViewport) return;
    doc.style.setProperty('--visual-height', `${window.innerHeight}px`);
    doc.style.setProperty('--visual-offset-top', '0');
};

export default () => {
    window.addEventListener('resize', layoutHeight);
    layoutHeight();

    if (window.visualViewport) {
        window.visualViewport?.addEventListener('resize', visualHeight);
        window.visualViewport?.addEventListener('scroll', visualHeight);
        visualHeight();
    }
};
