// noinspection CssUnresolvedCustomProperty

import styled from 'styled-components';
import { Defaults } from '../Defaults';

export default styled.div`
    margin-top: calc(var(--safe-area-inset-top, 0px) + var(--visual-offset-top));
    height: calc(var(--visual-height) - var(--safe-area-inset-top, 0px) - var(--safe-area-inset-bottom, 0px));
    width: calc(100vw - var(--safe-area-inset-left, 0px) - var(--safe-area-inset-right, 0px));
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    overscroll-behavior: none;
    ${Defaults}
`;
