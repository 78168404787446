import { useEffect, useState } from 'react';
import { light, dark } from '../themes';

export const useSelectedTheme = () => {
    const browserDarkMode = useHasBrowserDarkColorScheme();
    const storageDarkMode = localStorage.getItem('theme');

    if (storageDarkMode === 'light') return light;
    if (storageDarkMode === 'dark') return dark;
    if (browserDarkMode) return dark;
    return light;
};

const hasDarkMode = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

const useHasBrowserDarkColorScheme = () => {
    const [isDarkMode, setDarkMode] = useState<boolean>(hasDarkMode());

    useEffect(() => {
        const listener = () => { setDarkMode(hasDarkMode()); };

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', listener);

        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', listener);
        };
    }, []);

    return isDarkMode;
};
