import { useSyncExternalStore } from 'react';

export function createStore<T>(initialState: T) : Store<T> {
    let state = initialState;
    const listeners = new Set<Function>();

    const setState = (fn: (prev: T) => T) => {
        state = fn(state);
        listeners.forEach((listener) => listener());
    };

    const getSnapshot = () => state;

    const subscribe = (listener : Function) => {
        listeners.add(listener);
        return () => {
            listeners.delete(listener);
        };
    };

    const getServerSnapshot = () => state;

    return { setState, getSnapshot, getServerSnapshot, subscribe };
}

export interface Store<T> {
    setState: (fn: (prev: T) => T) => void,
    getSnapshot: () => T,
    getServerSnapshot: () => T,
    subscribe: (listener: Function) => () => void
}

export function useStore<T, R>(store: Store<T>, selector: (snapshot: T) => R) {
    return useSyncExternalStore(
        store.subscribe,
        () => selector(store.getSnapshot()),
        () => selector(store.getServerSnapshot())
    );
}
