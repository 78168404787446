import { type DefaultTheme } from 'styled-components';
import { shared } from './shared';

export const dark: DefaultTheme = {
    ...shared,
    colors: {
        default: '#CACCD0',
        defaultInverse: '#1E1F22',
        defaultSupport: '#2B2D30',

        primary: '#3474f0',
        primaryInverse: '#ffffff',

        secondary: '#3fab49',
        secondaryInverse: '#ffffff',

        tertiary: '#a8a8a8',
        tertiaryInverse: '#1E1F22',

        error: '#c94f4f',
        errorInverse: '#1E1F22',

        background: '#1E1F22',
        disabled: '#1B1B27',
        lightGray: '#111111',
        badge: '#cc0000',

        inputTextColor: '#ffffff',
        inputBackgroundColor: '#2B2D30',
        inputBorderColor: '#111111',
    },
    type: 'dark'
};
