import UISettings from './UISettings';

interface User extends UISettings {
    role: UserRole
    gender: 'Male' | 'Female'
    info: string
    email: string
    yearOfBirth: number
    id: number
    name: string
    notificationPreference: NotificationMethod
    subscriptionDate: string
    primaryRegion?: string
}

export enum UserRole {
    User = 'User',
    Student = 'Student',
    Mentor = 'Mentor',
    Supervisor = 'Supervisor',
    CourseMaker = 'CourseMaker',
    Admin = 'Admin'
}

export enum TextSize {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
    XLarge = 'XLarge',
    XXLarge = 'XXLarge'
}

export enum PreferredTextMethod {
    Inline = 'Inline',
    Block = 'Block'
}

export enum NotificationMethod {
    Direct = 'Direct',
    DailyDigest = 'DailyDigest',
    WeeklyDigest = 'WeeklyDigest',
    None = 'None'
}

export default User;
