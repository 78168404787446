import { useGet } from '@bbcs/bbcs-fetch';
import useLanguage from 'hooks/useLanguage';
import Translation from 'types/Translation';

const fallBackTranslations = { nl: 'sv' };

export const useTranslations = (purpose: 'quote' | 'read') => {
    const language = useLanguage();
    return useGet<Translation[]>(`/content/translations/${language}?purpose=${purpose}`);
};

export const useDefaultTranslation = () => {
    const language = useLanguage();
    const defaultTranslation = useGet<Translation>(`/content/translations/default/${language}`);
    return defaultTranslation?.abbreviation;
};

export const useFallBackTranslation = () => {
    const language = useLanguage();
    return fallBackTranslations[language];
};
