/* eslint-disable @typescript-eslint/no-unused-vars,no-unused-vars  */

import { useEffect } from 'react';
import User, { TextSize } from 'types/User';
import { useUser } from './useUser';
import { isEmpty } from '../util/validator/utils';
import { useDefaultTranslation, useFallBackTranslation } from './useTranslations';
import getTextSize from '../util/getTextSize';
import { createStore, Store, useStore } from './useStore';

/**
 * This method will return all the local preference (textSize, textMethod, bible).
 * These preference are loaded from the backend - if the user is logged on - but
 * are locally saved in the localStorage for quick access
 */
export const usePreferences = () => {
    const user = useUser(); // this is loaded from the user context so it is efficient
    const textMethod = getTextMethod(user);
    const textSize = getPreferredTextSize(user);
    const preferredBible = usePreferredTranslation(user);

    // When a user is logged on, the preferences have to stored into the session
    useEffect(() => setPreferencesFromUserProfile(user), [user]);

    return { textMethod, textSize, setTextMethod, setTextSize, ...preferredBible };
};

// This hook can be used when only the text size is required. this is a more performant hook
export const usePreferredTextSize = () => {
    const user = useUser();
    return getPreferredTextSize(user);
};

export const usePreferredTextSizeValue = () => {
    const user = useUser();
    const textSize = getPreferredTextSize(user);
    return getTextSize(textSize);
};

export const clearLocalSettings = () => {
    removeItem('preferredTextSize');
    removeItem('preferredTextMethod');
    removeItem('preferredBible');
};

const usePreferredTranslation = (user: User | undefined | null) => {
    const fallBackTranslation = useFallBackTranslation(); // this translation that always can be used
    const defaultTranslation = useDefaultTranslation(); // the default translation - which might be not available!
    const localStoreTranslation = getItem('preferredBible');
    const store = getBibleStore();
    const cached = useStore(store, (state) => state);

    useEffect(() => {
        // Nb. don't reuse storedTranslation here, because when a user logs off the session storage
        // will be cleaned. This is then handled here since that also will trigger a change in the user object
        const updated = getItem('preferredBible')
            ?? user?.preferredBible
            ?? localStoreTranslation
            ?? defaultTranslation
            ?? fallBackTranslation;

        if (updated !== cached) store.setState(_ => updated);
    }, [user, defaultTranslation, fallBackTranslation]);

    const setPreferredBible = (value: string) => {
        setItem('preferredBible', value);
        store.setState(_ => value);
    };

    const isDefaultTranslationLoaded = defaultTranslation !== undefined;
    return { preferredBible: cached, setPreferredBible, defaultTranslation, isDefaultTranslationLoaded, fallBackTranslation };
};

// This method will load all the user preferences from the user profile and store them
const setPreferencesFromUserProfile = (user: User | undefined) => {
    if (!user) return;
    storeIfNotSet('preferredBible', user.preferredBible);
    storeIfNotSet('preferredTextSize', user.preferredTextSize);
    storeIfNotSet('preferredTextMethod', user.preferredTextMethod);
};

// This method will store a presences if it is not yet set in the session
const storeIfNotSet = (key: string, value: any) => {
    const current = getItem(key);
    if (isEmpty(current)) setItem(key, value);
};

// This method will load the current preferred text method from the storage
const getTextMethod = (user: User | null | undefined) => {
    const textMethod = getItem('preferredTextMethod') ?? user?.preferredTextMethod;
    if (textMethod !== 'block') return 'inline';
    return 'block';
};

// This method will load the current preferred text size from the storage
const getPreferredTextSize = (user: User | null | undefined) => {
    const textSize = (getItem('preferredTextSize') ?? user?.preferredTextSize ?? TextSize.Medium);
    return textSize as TextSize;
};

const setTextSize = (value: TextSize) => setItem('preferredTextSize', value);

const setTextMethod = (value: 'block' | 'inline') => setItem('preferredTextMethod', value);

const setItem = (key: string, value: any) => localStorage.setItem(key, value);

const getItem = (key: string) => localStorage.getItem(key);

const removeItem = (key: string) => localStorage.removeItem(key);

const getBibleStore = () => {
    const global = (window as any);
    if (!global.preference) global.preference = createStore<string | undefined>(undefined);
    return global.preference as Store<string>;
};
