import ConfigContext from 'context/ConfigContext';
import { useContext } from 'react';

/**
 * Hook for loading the website configuration
 * @returns Config the config object
 * */
export default () => {
    const { config } = useContext(ConfigContext);
    return config;
};
