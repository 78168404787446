import React, { ReactNode, useEffect } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { useSelectedTheme } from 'hooks/useSelectedTheme';

export const ThemeProvider = (props : { children : ReactNode }) => {
    const { children } = props;
    const theme = useSelectedTheme();

    useEffect(() => {
        window.document.body.style.backgroundColor = `${theme.colors.background} !important`;
    }, [theme]);

    return (
        <StyledThemeProvider theme={theme}>
            {children}
        </StyledThemeProvider>
    );
};
