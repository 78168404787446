import { setRegionId } from 'context/useFetchConfig';
import settings from 'devSettings';
import releaseInfo from 'releaseInfo';
import Config from 'types/Config';

export default async (region: string | undefined) => {
    const query = region ? `?region=${region}` : '';
    const configUrl = `/config.json${query}`;
    const response = await fetch(configUrl, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const config = await response.json();
    return processConfig(config, region, configUrl);
};

const processConfig = async (config: any, initialRegion: string | undefined, configUrl: string) => {
    let { region } = config;
    const site = getHostname();

    // eslint-disable-next-line no-console
    console.log(`App ${releaseInfo.buildId} started. config: ${configUrl}, site: ${site}, region: ${region}`);
    const mode = config?.mode?.toLowerCase();

    // If we are dealing with app mode the region of the user needs to be retrieved
    // through the api. It is also stored directly into the cache of the browser
    if (mode === 'app') region = await getAppRegion(initialRegion, config.api);
    return { ...config, mode, site, region } as Config;
};
const getAppRegion = async (initialRegion: string | undefined, api: string) => {
    if (initialRegion) return initialRegion;
    const response = await fetch(`${api}/get-region`);
    const { selected } = await response.json();
    setRegionId(selected);
    return selected;
};

// The hostname should return the hostname that is used load the configuration
const getHostname = () => {
    // Check if to use to development settings
    const { useLocalConfig, host, isDevEnvironment } = settings;
    if (isDevEnvironment() && useLocalConfig) return host;

    // Nb. the development hosts are set to localhost
    if (isDevEnvironment()) return 'localhost';
    return window.location.hostname;
};
